import { useEffect } from "react";
import { useDashboard } from "../context/dashboard";
import { Container, Wrapper } from "./index.styled";
import ProjectList from "./ProjectList";
import ProjectOverview from "./ProjectOverview";
import AddNewProject from "./AddProject";
import { useDashboardInfo } from "../hooks/useDashboardInfo";
import { useAuth } from "../../../../context/auth";
function Root() {
  const {
    dashboardState: { selectedProject, popupActive, projectList }, dashboardState
  } = useDashboard();
  const useDashboardInfoHook = useDashboardInfo();

  const { authState, authDispatch } = useAuth()

  useEffect(() => {
    if(authState.selectedProject){
      useDashboardInfoHook.getIndividualProject(authState.selectedProject.id);
    }
  }, [authState.selectedProject])

  return (
    <Wrapper>
      <Container paddingProps={selectedProject && projectList.length != 1 ? true : false}>
        {selectedProject ? <ProjectOverview /> : <ProjectList />}
      </Container>
      {popupActive && <AddNewProject />}
    </Wrapper>
  );
}

export default Root;
