import GradientBackground from "../GradientBackground";
import { useAdGenerator } from "../../context/adGeneration";
import statics from "../../statics";
import { Back, Header, Info, Name, TitleAndNav } from "./index.styled";
import { useEffect, useRef } from "react";
import back from "./assets/back.svg";
import AdGoal from "./components/step1/index";
import Description from "./components/step2";
import DestinationUrl from "./components/step3";
import BrandDetails from "./components/step4";
import TargetAudience from "./components/step5";
import AdType from "./components/step6/ImageAd";
import SeedKeywords from "./components/step6/TextAd";
const InputPage = ({ forTextAd }) => {
  const { steps } = statics;
  const {
    adGeneratorState: { currentInputPageIndex },
    adGeneratorDispatch,
  } = useAdGenerator();
  const nameRef = useRef();
  useEffect(() => {
    if (nameRef.current) {
      nameRef.current.innerHTML =
        currentInputPageIndex < 5
          ? steps[currentInputPageIndex].header
          : forTextAd
          ? steps[5].text.header
          : steps[5].image.header;
    }
  }, [currentInputPageIndex]);
  const handleBack = () => {
    if (currentInputPageIndex !== 0) {
      adGeneratorDispatch({
        type: "SET_CURRENT_INPUT_PAGE_INDEX",
        value: currentInputPageIndex - 1,
      });
    }
  };
  const handleNext = () => {
    if (currentInputPageIndex !== steps.length - 1) {
      adGeneratorDispatch({
        type: "SET_CURRENT_INPUT_PAGE_INDEX",
        value: currentInputPageIndex + 1,
      });
    }
  };

  const renderStep = () => {
    console.log(currentInputPageIndex, "currentInputPageIndex");
    switch (currentInputPageIndex) {
      case 0:
        return <AdGoal onNext={handleNext} goals={steps[0].options} />;

            // case 1:
            // return <Description onNext={handleNext} />

            case 1:
            return <DestinationUrl onNext={handleNext} />

            case 2:
            return <BrandDetails onNext={handleNext} forTextAd={forTextAd} />

            case 3:
            return <TargetAudience onNext={handleNext} />

            case 4:
                if(!forTextAd) return <AdType />
                return <SeedKeywords />
        }
    
  };

  return (
    <GradientBackground>
      <Header>
        <TitleAndNav>
          {currentInputPageIndex !== 0 && (
            <Back onClick={handleBack} src={back} draggable={false} />
          )}
          <Name ref={nameRef} makeBold={currentInputPageIndex > 2}></Name>
        </TitleAndNav>
        {currentInputPageIndex > 2 && <Info>{steps[3].info}</Info>}
      </Header>
      {renderStep()}
    </GradientBackground>
  );
}

export default InputPage;
