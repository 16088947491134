import styled from 'styled-components';

export const SideNavContainer = styled.div`
    background: #FFF; 
    background: #111926;
    /* border-right: 1px solid #DADFE4; */
    display: flex;
    flex-direction: column;
    height: 100vh;
    /* height: calc(100vh - 54px); */
    /* max-width: ${props => props.isNavCollapsed ? "80px" : "285px"}; */
    width: ${props => props.isNavCollapsed ? "80px" : "285px"};
    position: relative;
    transition: .3s;
`;

export const Logo = styled.div`
    /* width: 200px; */
    height: 54px;
    display: flex;
    justify-content: ${props => props.isNavCollapsed ? "center" : "flex-start"};
    margin-left: ${props => props.isNavCollapsed ? "0px" : "20px"};
    align-items: center;
    cursor: pointer;
`;

export const CollapsedIcon = styled.img`
    transform: ${props => props.isNavCollapsed ? "rotate(0deg)" : "rotate(180deg)"};  
    width: 20px;
`;

export const GroupContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 14px;
        padding: 8px 18px;
    /* margin-bottom: 18px;  */

    /* padding-top: ${props => props.collapsedState ? '10px' : '0px'};  */
    /* margin: ${props => props.collapsedState ? '0px 22px 12px 8px' : '0px 0px 18px 0px'}; */

    background: ${props => props.collapsedState && props.active ? "#292F3D" : "none"};
    border-radius: ${props => props.collapsedState && props.active ? "12px" : "none"};

    /* margin: 3px 3px 0px; */

    /* p{
        color: #1C1C1C;
        padding: 6px 8px 6px 0px;
        margin-bottom: 4px;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    p img{
        margin-right: 8px;
        transform: ${props => props.closed ? "rotate(180deg)" : "rotate(0deg)"};
        animation-timing-function: ease-in; 
    }
    p span{
        display: flex;
    }
    
    .collapsed-icon-div{
        color: #1C1C1C;
        margin-bottom: 4px;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        width: 44px;
        height: 36px;
        justify-content: center;
        padding: 0px;    
    }

    div > img{
        
    } */
`;

export const MenuHeader = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    /* margin-bottom: 10px; */
    /* padding: ${props => props.collapsedState ? '0px 0px 0px 8px' : '0px 0px 0px 9px'};  */

    div{
        display: flex;
        align-items: center;
        gap: 8px;
        img{
            width: 20px;
        }
    }
    p{
        color: ${props => props.active ? "rgba(255, 255, 255, 0.85)" : "#9499A4"};
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        cursor: pointer;
        /* text-transform: uppercase  */
    }

    .menuheader-arrow{
        margin-right: 10px;
        filter: invert(0.7);
        transform: ${props => !props.isOpen ? "rotate(90deg)" : ""};
    }

    p img{
        margin-right: 4px;
        transform: ${props => props.closed ? "rotate(90deg)" : "rotate(0deg)"}; 
        animation-timing-function: ease-in; 
    }
    
    /* div img{
        background: ${props => props.collapsedState && props.active ? "#292F3D" : "none"};
    } */

`;

export const Wrapper = styled.div`
    
`;

export const LinkElement = styled.span`
    margin-bottom: 2px;
    border-radius: 12px;
    background: ${props => props.activePath ? "#292F3D" : "none"};
    display: flex;

    div{
        text-decoration: none;
        padding: 8px 8px 8px 40px;
        cursor: pointer;
        border-radius: 12px;
        display: flex;
        width: 100%;
        /* opacity: 0.8; */
        :hover{
            background: rgba(28, 28, 28, 0.05);
        }
    }

    div p{
        color: ${props => props.activePath ? "#BBBDC1" : "#9499A4"};
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
    }
`;

export const LinkElements = styled.div`
/* margin-right: 5px;  */
    border-radius: 12px;
    background: ${props => props.activePath ? "#292F3D" : "none"}; 

    span {
        color: ${props => props.activePath ? "#BBBDC1" : "#9499A4"};
    }
`;

export const LinkElementsCopy = styled(LinkElements)`
a{
    margin-left: ${props => props.isNavCollapsed ? "8px" :""};
}
`;


export const LevelOneContainer = styled.div`
    display: flex;
    flex-direction: column; 
    border-bottom: solid 1px #DADFE4;
    padding: 24px 10px 16px; 
    h2{
        margin-bottom: 20px;
        font-size: 12px;
        font-weight: 500;
        color: #C3C3C3;
        margin-left: 10px;
        line-height: 18px;
    }
    /* p{
        margin-bottom: 20px;
        font-size: 12px;
        font-weight: 500;
        color: #C3C3C3;
        margin-left: 10px;
        line-height: 18px;
    } */
    a{
        text-decoration: none;
        padding: 8px 10px; 
        color: rgba(28, 28, 28, 0.80);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        border-radius: 24px;
        display: flex;
        align-items: center; 
        :hover{
            outline: 1px solid #1C1C1C33;
        }
    }
    img{
        width: 20px; 
        margin-right: 12px;
    }
    span{
        color: #9499A4;
    }
`;

export const LevelOneContainerCopy = styled(LevelOneContainer)`
    border-bottom: none; 
    padding: 0px 0px;
    /* margin-right: 22px ;  */
    padding: 0px 22px 0px 6px;
    h2{
        margin-bottom: 8px;
        font-size: 12px;
        font-weight: 500;
        color: #C3C3C3;
        margin-left: 10px;
        line-height: 18px;
        margin-top: 16px;
    }

    a { 
        /* padding: ${props => props.isNavCollapsed ? '0px 0px 15px 0px' : '8px 8px 8px 10px'};   */ 
        padding: 10px 10px 10px 10px;
    }
`;

export const LevelTwoContainer = styled.div`
    display: flex;
    flex-direction: column;

    padding: 8px 10px;

    overflow-y: auto;
    height: calc(100vh - 225px);
    margin: 4px 4px 0px 0px;
    h2{
        margin-bottom: 8px;
        font-size: 12px;
        font-weight: 500;
        color: #C3C3C3;
        margin-left: 10px;
        line-height: 18px;
        margin-top: 16px;
    }
    
    p{
        /* margin-bottom: 20px; */
        /* font-size: 12px;
        font-weight: 500;
        color: #9499A4; */
        /* margin-left: 10px; */
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: transparent;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: transparent;
        border-radius: 10px;
        margin-right: 5px;
        width: 5px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: #666666;
        border-radius: 10px;
        width: 5px;
    }
    p{
        /* margin-bottom: 20px; */
    }
`;

export const InnerMenu = styled.div`
    display: ${props => props.closed ? "none" : "unset"};
    transition: transform 12s ease;
    padding: 8px 15px 0px;
`;

export const ToggleNavContainer = styled.div`
    /* background: #FFF;  */
    stroke-width: 1px;
    color: #374957;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    right: 24px;
    cursor: pointer;
    top: 14px;
    z-index: 3;
    /* border: solid 1px #80808036;  */
    align-items: center;
    justify-content: center;    

    img{
        /* transform: ${props => props.isNavCollapsed ? "rotate(0deg)" : "rotate(180deg)"};          */
        height: 20px;
        transition: .5s;
    }

`;

export const NavItemsContainer = styled.div`

`;

export const FinishBtn = styled.div`
    padding: 10px 18px;
    height: 44px;
    color: white;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid rgb(255, 113, 30);
    background: rgb(255, 113, 30);
    box-shadow: rgba(16, 24, 40, 0.05) 0px 1px 2px 0px;
    margin-left: 7px;
`;

export const CustomComponentContainer = styled.div`
    img{
        width: 100%;
    }
    h1{
        font-weight: 500;
    }
    p{
        margin-bottom: 30px;
        font-weight: 300;
    }
`;