import React from 'react'
import { useReducer, createContext, useContext } from 'react'

import produce from 'immer'

const initialState = {
    currentPage: "home",
    adGenerationType: null,

    generatedAdId: null,
    currentInputPageIndex: 0,
    goal: null,
    description: null,
    destinationUrl: null,
    keywordSuggestion: [],
    brandDetails: {
        name: null,
        description: null,
        productDetails: null,
        logo: null,
        colors: [],
        images: [],
        stockImages: []
    },
    targetAudience: {
        gender: null,
        age: {from: 18, to: 24},
        ageGroup: [],
        languages: ["English"],
        occupations: [],
        locations: ["India"]
    },
    adType: "socialMedia",
    occupationItem: [],
    // image ad
    isGenerationProcess: null,
    adGroupItem: null,
    isAdDetails: null,
    wesbiteMetaLoading: false
}

const adGeneratorReducer = produce((draft, action) => {
    switch(action.type) {
        case "SET_CURRENT_PAGE":
            draft.currentPage = action.value
        break

        case "SET_GENERATED_AD_ID":
            draft.generatedAdId = action.value
        break

        case "SET_ADGENERATION_TYPE":
            draft.adGenerationType = action.value
        break

        case "SET_KEYWORD_SUGGESTION":
            draft.keywordSuggestion = action.value
        break

        case "SET_CURRENT_INPUT_PAGE_INDEX":
            draft.currentInputPageIndex = action.value
        break

        case "SET_GOAL":
            draft.goal = action.value
        break

        case "SET_DESCRIPTION":
            draft.description = action.value
        break

        case "SET_DESTINATION_URL":
            draft.destinationUrl = action.value
        break

        case "SET_BRAND_DETAILS":
            draft.brandDetails = {...draft.brandDetails, ...action.value}
        break

        case "SET_TARGET_AUDIENCE":
            draft.targetAudience = action.value
        break

        case "SET_AD_TYPE":
            draft.adType = action.value
        break
        case "SET_WEBSITE_META_LOADING":
            draft.wesbiteMetaLoading = action.value
        break
        // text ad
        case "SET_GENERATION_PROCESS":
            draft.isGenerationProcess = action.value;
        break;
        case "SET_AD_DETAILS":
            draft.isAdDetails = action.value;
        break;
        case "SET_ADGROUP_ITEM":
            draft.adGroupItem = action.value;
        break;
        case "SET_OCCUPATION_ITEM":
            draft.occupationItem = action.value;
        break;
        default: 
        break;
    }
})

const useAdGeneratorReducer = () => {
    return useReducer(adGeneratorReducer, initialState)
}

const AdGeneratorContext = createContext()

export const AdGeneratorProvider = ({children}) => {
    const [state, dispatch] = useAdGeneratorReducer()
    const value = { adGeneratorState: state, adGeneratorDispatch: dispatch }

    return <AdGeneratorContext.Provider value={value}>
        {children}
    </AdGeneratorContext.Provider>
}

export const useAdGenerator = () => {
    return useContext(AdGeneratorContext)
}


