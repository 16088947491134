import styled from "styled-components";

export const CampaignOuterWrap = styled.div`
  display: flex;
  padding-top: 40px;
  flex-direction: column;
  gap: 24px;
  h3 {
    color: #000;
    font-size: 24px;
    font-weight: 600;
    line-height: 38px; /* 158.333% */
  }
`;
export const CampaignTableWrap = styled.div`
  border-radius: var(--8, 8px);
  border: 1px solid #e3e3e3;
  background: #fff;
  padding: 29px 29px 26px 32px;
`;
export const ActiveCampaign = styled.div`
  color: #4f4f4f;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%; /* 24px */
`;
export const TableWrap = styled.div`
  min-width: 1139px;
  /* height: 484px; */
  height: 492px;
  border-radius: 6px;
  background: #fff;
  margin-top: 24px;
`;
export const TableHeader = styled.div`
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #667085;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px; /* 150% */
  border-bottom: 1px solid #eaecf0;
  background: #f9f9f9;
  #campaignHead {
    width: 35.4%;
    padding-left: 24px;
  }
  #plaformHead {
    width: 7.6%;
    text-align: center;
  }
  #impressionHead {
    width: 9.1%;
    text-align: center;
  }
  #performanceHead {
    width: 12%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }
  #adspyderHead {
    width: 12.8%;
    text-align: center;
  }
`;

export const TableBody = styled.div``;
export const Row = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e3e8ee;
  height: 44px;
`;
export const NameDiv = styled.div`
  color: rgba(28, 28, 28, 0.8);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  width: 35.4%;
  padding-left: 24px;
`;
export const ImpressionDiv = styled.div`
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  width: 9.1%;
  padding-right: 24px;
  text-align: end;
`;
export const PlatformDiv = styled.div`
  width: 7.6%;
  display: flex;
  justify-content: center;
`;
export const PerformanceDiv = styled.div`
  width: 12%;
  display: flex;
  justify-content: center;
  p {
    width: fit-content;
    display: flex;
    padding: 2px 10px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background: #f0fdf9;
    border-radius: 4px;
    border: 1px solid #dadfe4;
    color: rgba(28, 28, 28, 0.7);
    font-size: 12px;
    font-weight: 500;
    line-height: 18px; /* 150% */
  }
`;
export const Round = styled.div`
  width: 10px;
  height: 10px;
  background: #089569;
  border-radius: 50%;
`;
export const AdsyderDiv = styled.div`
  width: 12.8%;
  display: flex;
  justify-content: center;
  gap: 16px;
  align-items: center;
  img {
    cursor: pointer;
  }
  div.radial-gauge {
    width: 34px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      height: 60px;
    }
  }
`;
export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: end;
  padding-top: 25px;
  gap: 24px;
  align-items: center;
`;
export const RowsList = styled.div`
  color: #adadad;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  span {
    color: var(--Gray-700, #344054);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }
`;
export const RightArrow = styled.div`
  border-radius: var(--8, 8px);
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--White, #fff);
  height: 36px;
  width: 36px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    height: 20px;
    width: 20px;
    opacity: ${(props) => (props.disabled ? "40%" : 1)};
  }
`;
export const LeftArrow = styled.div`
  border-radius: var(--8, 8px);
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--White, #fff);
  height: 36px;
  width: 36px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  pointer-events: ${(props) => (props.disabled ? "none" : "")};
  img {
    width: 20px;
    height: 20px;
    transform: rotate(180deg);
    opacity: ${(props) => (props.disabled ? "40%" : 1)};
  }
`;
export const ScrollWraper = styled.div`
  overflow-x: auto;
`;
