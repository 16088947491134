import AnalyzingLoader from "../../../../AnalyzingLoader";
import { Column, Container, Description, Details, GenerateButton, Image, Label, Platforms, PlatfrormsConatiner, Title, Type, TypeDescription, Value } from "./index.styled";
import socialmedia from "./assets/socialmedia.svg"
import display from "./assets/display.svg"
import statics from "../../../../../statics";
import meta from "./assets/meta.svg"
import linkedin from "./assets/linkedin.svg"
import tiktok from "./assets/tiktok.svg"
import twitter from "./assets/twitter.svg"
import reddit from "./assets/reddit.svg"
import google from "./assets/google.png"
import bing from "./assets/bing.png"
import { useAdGenerator } from "../../../../../context/adGeneration";
import { useEffect, useState } from "react";
function AdType() {
    const { steps } = statics
    const { adGeneratorState: {adType}, adGeneratorDispatch } = useAdGenerator()
    const { image: {types} } = steps[5]

    const [selectedAdType, setSelectedAdType] = useState(types.find((type) => type.programmaticName === adType))

    const findPlatform = (platform) => {
        switch(platform){
            case "meta": return meta
            case "linkedin": return linkedin
            case "tiktok": return tiktok
            case "twitter": return twitter
            case "reddit": return reddit
            case "google": return google
            case "bing": return bing
        }
    }

    const handleSelect = (adType) => {
        adGeneratorDispatch({
            type: "SET_AD_TYPE",
            value: adType
        })
        console.log('selected')
    }

    useEffect(() => {
        setSelectedAdType(types.find((type) => type.programmaticName === adType))
    }, [adType])

    return ( 
        <Container>
            {/* <AnalyzingLoader text={"Recommending ad type..."} /> */}
            <Label>Select a ad type</Label>
            <Value>
                <Column>
                    {types.map((t, index) => {
                        const { type, platforms, programmaticName } = t
                        const isSelected = programmaticName === adType
                        console.log('isSelected', isSelected)
                        return(
                            <Type key={index} isSelected={isSelected} onClick={() => handleSelect(programmaticName)} >
                                <Details>
                                    <Image src={ index === 0 ? socialmedia : display} />
                                    {type}
                                </Details>
                                <PlatfrormsConatiner>
                                    Platforms:
                                    <Platforms>
                                        {platforms.map((platform, idx) => {
                                            const icon = findPlatform(platform)
                                            return(
                                                <Image key={`platform+${index}${idx}`} src={icon} draggable={false} />
                                            )
                                        })}
                                        <Image />
                                    </Platforms>
                                </PlatfrormsConatiner>
                            </Type>
                        )
                    })}
                </Column>
                {selectedAdType && <Column>
                    <TypeDescription>
                        <Title>{selectedAdType.type}</Title>
                        <Description>{selectedAdType.description}</Description>
                    </TypeDescription>
                    <GenerateButton>Generate</GenerateButton>
                </Column>}
            </Value>
        </Container>
     );
}

export default AdType;