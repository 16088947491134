import GoogleLogo from '../../../../../media/Dashboard/Google16.svg'
import BingLogo from '../../../../../media/Dashboard/Bing16.svg'
import YoutubeLogo from '../../../../../media/Dashboard/YouTube16.svg'
import MetaLogo from '../../../../../media/Dashboard/meta16.png'
import LinkedinLogo from '../../../../../media/Dashboard/LinkedIn16.svg'
import RedditLogo from '../../../../../media/Dashboard/Reddit16.svg'
import GooglePlaLogo from '../../../../../media/Dashboard/Googlepla16.svg'
import BingPlaLogo from '../../../../../media/Dashboard/Bingpla16.svg'
import FlipkartLogo from '../../../../../media/Dashboard/flipkart16.png'
import AmazonLogo from '../../../../../media/Dashboard/amazon16.svg'
import DisplayLogo from '../../../../../media/Dashboard/Display16.svg'

import GeneratIcon from '../../../../../media/Dashboard/add.svg'
import GenerateNewIcon from '../../../../../media/Dashboard/link.svg'
import DraftIcon from '../../../../../media/Dashboard/draft.svg'

import CalanderIcon from '../../../../../media/Dashboard/calender.svg'
import EditIcon from '../../../../../media/Dashboard/edit-white.svg'
import GlobeIcon from '../../../../../media/Dashboard/globe.svg';

import SampleHistoryImage from '../../../../../media/Dashboard/dummy-downloaded.png'

import { Wrapper, CtaContainer, PersonaListContainer, HistoryContainer, LeftCtaContent, RightCtaContent, PlatformContainer, PlatformList, GenerateButton, GenerateFromLinkButton, DraftButton, HistoryList, HistoryCard, ImageContainer, HistoryCardContent, MaxSizeWrapper, HistoryCardLeft, HistoryCardRight, DateContainer, NameContainer, EditNameContainer, CardBody, Header, LogoWrapper, TitleWrap, KeywordWrap, DescriptionWrap, Title, LinkWrap } from "./index.styled.js"
import { convertTimestamp } from '../../../../../utils/dateFormat.js'
import { useNavigate } from 'react-router-dom'

const HomePage = ({ adType, historyData }) => {
    const navigate = useNavigate()
    const PlatformItems = [
        {
            image: GoogleLogo,
            imagead: true,
            textad: false,
            width: "25px"
        },
        {
            image: BingLogo,
            imagead: true,
            textad: false,
            width: "25px"
        },
        {
            image: YoutubeLogo,
            imagead: true,
            textad: false,
            width: "25px"
        },
        {
            image: MetaLogo,
            imagead: false,
            textad: true,
            width: "25px"
        },
        {
            image: LinkedinLogo,
            imagead: true,
            textad: false,
            width: "25px"
        },
        {
            image: RedditLogo,
            imagead: false,
            textad: true,
            width: "25px"
        },
        {
            image: GooglePlaLogo,
            imagead: true,
            textad: false,
            width: "35px"
        },
        {
            image: BingPlaLogo,
            imagead: false,
            textad: true,
            width: "35px"
        },
        {
            image: AmazonLogo,
            imagead: true,
            textad: false,
            width: "25px"
        },
        {
            image: DisplayLogo,
            imagead: true,
            textad: false,
            width: "25px"
        }

    ]

    return <Wrapper>
        <CtaContainer>
            <MaxSizeWrapper style={{ flexDirection: "row" }}>
                <LeftCtaContent>
                    <h1>
                        Generate {adType == "imagead" ? "image" : "text"} ads
                    </h1>
                    <p>Compatible on {adType == "imagead" ? "10+" : "3+"} platforms</p>
                    <PlatformList>
                        {PlatformItems.map(platformImg => {
                            if (adType == "imagead" ? platformImg.imagead : platformImg.textad) {
                                return <PlatformContainer>
                                    <img style={{ width: platformImg.width }} src={platformImg.image} />
                                </PlatformContainer>
                            }
                        })}
                        <PlatformContainer>
                            <span>+more</span>
                        </PlatformContainer>
                    </PlatformList>
                </LeftCtaContent>
                <RightCtaContent>
                    <div>
                        <GenerateButton onClick={() => navigate(`/adgenerator-v3/${adType == "imagead" ? "image-ad" : "text-ad"}/input`)}>
                            <img src={GeneratIcon} />
                        </GenerateButton>
                        <p>AI Generate</p>
                    </div>
                    <div>
                        <GenerateFromLinkButton>
                            <img src={GenerateNewIcon} />
                        </GenerateFromLinkButton>
                        <p>Generate from link</p>
                    </div>
                    <div>
                        <DraftButton onClick={() => navigate(`/adgenerator-v3/${adType == "imagead" ? "image-ad" : "text-ad"}/drafts`)}>
                            <img src={DraftIcon} />
                        </DraftButton>
                        <p>Drafts</p>
                    </div>
                </RightCtaContent>
            </MaxSizeWrapper>
        </CtaContainer>
        <PersonaListContainer>
            <MaxSizeWrapper>
                <h2>Personas to target</h2>
            </MaxSizeWrapper>
        </PersonaListContainer>
        <HistoryContainer>
            <MaxSizeWrapper>
                <h2>History</h2>
                <HistoryList>
                    {historyData.map(history => {
                        return <HistoryCard adType={adType}>
                            <ImageContainer >
                                {adType != "imagead" ? <CardBody>
                                    <Header>
                                        <LogoWrapper>
                                            <img src={GlobeIcon} />
                                        </LogoWrapper>
                                        <TitleWrap>
                                            <Title>{history.domainId}</Title>
                                            <LinkWrap>{history.url}</LinkWrap>
                                        </TitleWrap>
                                    </Header>
                                    <KeywordWrap>{history.title}</KeywordWrap>
                                    <DescriptionWrap>
                                        {`${convertTimestamp(history.lastSeen)} __ ${history.content}`}
                                    </DescriptionWrap>
                                </CardBody> : <img src={SampleHistoryImage} />}
                            </ImageContainer>
                            <HistoryCardContent>
                                <HistoryCardLeft>
                                    <DateContainer>
                                        <img src={CalanderIcon} /><span>23-07-2024</span>
                                    </DateContainer>
                                    <NameContainer>
                                        Couponswala
                                    </NameContainer>
                                </HistoryCardLeft>
                                <HistoryCardRight>
                                    <EditNameContainer>
                                        <img src={EditIcon} />
                                    </EditNameContainer>
                                </HistoryCardRight>
                            </HistoryCardContent>
                        </HistoryCard>
                    })}
                </HistoryList>
            </MaxSizeWrapper>
        </HistoryContainer>
    </Wrapper>
}

export default HomePage