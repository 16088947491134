import GoogleAdsImg from "../../../../media/Dashboard/GoogleAds.png";
import facebookAdsImg from "../../../../media/Dashboard/facebookNew.svg";
import linkedinAdsImg from "../../../../media/Dashboard/linkedin.svg";
import amazonAdsImg from "../../../../media/Dashboard/amazon.svg";
import AdspyderNav from "../../../../media/Dashboard/AdspyderNav.svg";

const adResultData = {
  platformArray: [
    { platform: GoogleAdsImg, title: "Google AD account", connected: true },
    { platform: facebookAdsImg, title: "Facebook AD account", connected: true },
    {
      platform: linkedinAdsImg,
      title: "LinkedIn AD account",
      connected: false,
    },
    { platform: amazonAdsImg, title: "Amazon AD account", connected: false },
  ],
  metricDetails: [
    { title: "Return on Ad Spend (ROAS)", spend: "4.01x" },
    { title: "Cost-per-click (CPC)", spend: "$0.9" },
    { title: "Conversion", spend: "2.50%" },
  ],
  tabOptions: ["Impressions", "Clicks", "CTR", "Total Spent"],
  dummyData: [
    {
      campaignName: "Sale | Search | Masterchow Store | 12-5-22",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10,000",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Amazon Deals Page | Sales | Display | 13-10-2022",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "500",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Sale | Search | Masterchow Store | 12-5-22",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10,000",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Amazon Deals Page | Sales | Display | 13-10-2022",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10,000",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Sale | Search | Masterchow Store | 12-5-22",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10,000",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Sale | Search | Masterchow Store | 12-5-22",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Sale | Search | Masterchow Store | 12-5-22",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10,000",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Amazon Deals Page | Sales | Display | 13-10-2022",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10,000",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Sale | Search | Masterchow Store | 12-5-22",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10,000",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Sale | Search | Masterchow Store | 12-5-22",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "200",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Amazon Deals Page | Sales | Display | 13-10-2022",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10,000",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Amazon Deals Page | Sales | Display | 13-10-2022",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "500",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Sale | Search | Masterchow Store | 12-5-22",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10,000",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Amazon Deals Page | Sales | Display | 13-10-2022",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10,000",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Sale | Search | Masterchow Store | 12-5-22",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10,000",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Amazon Deals Page | Sales | Display | 13-10-2022",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10,000",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Amazon Deals Page | Sales | Display | 13-10-2022",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "200",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Sale | Search | Masterchow Store | 12-5-22",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10,000",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Amazon Deals Page | Sales | Display | 13-10-2022",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "500",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Amazon Deals Page | Sales | Display | 13-10-2022",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10,000",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Amazon Deals Page | Sales | Display | 13-10-2022",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10,000",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Amazon Deals Page | Sales | Display | 13-10-2022",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10,000",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Amazon Deals Page | Sales | Display | 13-10-2022",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Amazon Deals Page | Sales | Display | 13-10-2022",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10,000",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Amazon Deals Page | Sales | Display | 13-10-2022",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10,000",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Sale | Search | Masterchow Store | 12-5-22",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "10,000",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
    {
      campaignName: "Sale | Search | Masterchow Store | 12-5-22",
      campaignImg: AdspyderNav,
      platforms: "google",
      impressions: "200",
      itemClick: "1,250",
      CTRate: "7.2%",
      TotalSpent: "$0.06",
      PerformanceScore: "92 - Good",
    },
  ],
  detailsOption: [
    { option: "Visits" },
    { option: "Clicks" },
    { option: "Conversions" },
    { option: "Cost" },
    { option: "Profit" },
    { option: "Impression" },
    { option: "ROI" },
  ],
  tableDetails: [
    {
      Campaign: [
        {
          status: true,
          description: "Sale | Search | Masterchow Store | 12-5-22Sale | Search | Masterchow Store ",
          profits: 10000,
        },
        {
          status: false,
          description: "Amazon Deals Page | Sales | Display | 13-10-2022Sale | Search | Masterchow Store ",
          profits: 300,
        },
        {
          status: true,
          description: "Sale | Search | Masterchow Store | 12-5-22Sale | Search | Masterchow Store ",
          profits: 4000,
        },
        {
          status: true,
          description: "Sale | Search | Masterchow Store | 12-5-22",
          profits: 2100,
        },
        {
          status: true,
          description: "Sale | Search | Masterchow Store | 12-5-22",
          profits: 2100,
        },
      ],
    },
    {
      "Traffic Source": [
        {
          status: true,
          description: "Google ads",
          profits: 10000,
        },
        {
          status: false,
          description: "Facebook ads",
          profits: 300,
        },
        {
          status: true,
          description: "Tik Tok",
          profits: 4000,
        },
        {
          status: true,
          description: "Linkedin",
          profits: 2100,
        },
        {
          status: true,
          description: "Reddit",
          profits: 2000,
        },
      ],
    },
    {
      Audience: [
        {
          status: true,
          description: "Female",
          profits: 10000,
        },
        {
          status: false,
          description: "Young Adults (20-29 years)",
          profits: 300,
        },
        {
          status: true,
          description: "Adults (30-39 years)",
          profits: 4000,
        },
        {
          status: true,
          description: "Middle-aged Adults (40-54 years)",
          profits: 2100,
        },
        {
          status: true,
          description: "Top 20% Household income",
          profits: 2000,
        },
      ],
    },
    {
      "Countries": [
        {
          status: true,
          description: "India",
          profits: 10000,
        },
        {
          status: false,
          description: "United States",
          profits: 300,
        },
        {
          status: true,
          description: "Singapore",
          profits: 4000,
        },
        {
          status: true,
          description: "Dubai",
          profits: 2100,
        },
        {
          status: true,
          description: "London",
          profits: 2000,
        },
      ],
    },
  ],
};
export default adResultData;
