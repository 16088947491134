import styled from "styled-components";

export const ResultWrapper = styled.div`
  width: 100%;
  display: flex;
`;
export const ResultContainer = styled.div`
  width: 64.5%;
  height: 100%;
  /* max-width: 932px; */
`;
export const AdGroupWrap = styled.div`
  height: 52px;
  border-bottom: 1px solid rgba(28, 28, 28, 0.1);
  padding-top: 19px;
  width: 100%;
  padding-left: 40px;
`;
export const AdGroupContainer = styled.div`
  display: flex;
  gap: 20px;
  overflow-x: auto;
  width: 100%;
  padding-bottom: 4px;
  &::-webkit-scrollbar {
    height: 4px;
  }
`;

export const NavAdDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  white-space: nowrap;
`;
export const HighlightLine = styled.div`
  width: 100%;
  height: 3px;
  background: #1c1c1c;
  visibility: ${(props) => (props.isActive ? "auto" : "hidden")};
`;
export const AdGroupValue = styled.div`
  padding: 0 15px 0 10px;
  color: var(--black-100, #1c1c1c);
  font-family: Inter;
  font-size: 14px;
  font-weight: ${(props) => (props.isActive ? "600" : "400")};
  cursor: pointer;
`;
