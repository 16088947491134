import styled, { keyframes } from "styled-components";

export const Container = styled.div`
display: flex;
flex-direction: column;
gap: 24px;
`;

const fadeIn = keyframes`
0%{
    opacity: 0;
}

50%{
    opacity: 0.5;
}

100%{
    opacity: 1;
}
`; 

export const Property = styled.div`
animation: 2s linear ${fadeIn};
position: relative;
`;

export const Label = styled.label`
color: #1C1C1C;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-bottom: 16px;
display: flex;
gap: 16px;
align-items: center;
span{
  font-size: 14px;
  font-weight: 400;
  color: #9C9C9C;
}
`;

export const Value = styled.textarea`
display: block;
width: 72.4%;
height: ${(props) => props.short ? "66px" : "162px"}; //props.short ? "6.673407482%" : "16.380182002%"
flex-shrink: 0;
border-radius: 6px;
border: 1px solid #E8E8E8;
background: #FFF;
padding: 22px;
color: #1C1C1C;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: 150%; /* 21px */
resize: none;
border: none;
outline: none;
::focus{
border: none;
outline: none;
}
`;

export const Assets = styled.div`
margin-top: 8px;
display: flex;
gap: 56px;
`;

export const Asset = styled.form`

`

export const LogoWrapper = styled.div`
display: flex;
gap: 5px;
`;

export const LogoContainer = styled.div`
width: 144px;
height: 67px;
display: flex;
align-items: center;
justify-content: center;
border-radius: 6px;
border: 1px solid #9C9C9C;
background: #FFF;
`;

export const BrandLogo = styled.img`
width: 92.503px;
height: 30px;
flex-shrink: 0;
`;

export const EditContainer = styled.div`
display: flex;
width: 36px;
height: 36px;
position: relative;
justify-content: center;
align-items: center;
flex-shrink: 0;
cursor: pointer;
border-radius: 2.43px;
border: 0.405px solid #B2B2B2;
background: #FFF;
`;

export const Edit = styled.img`

`;

export const InputDummy = styled.input`
position: absolute;
height: 100%;
width: 100%;
cursor: pointer;
opacity: 0;
display: block;
`;

export const ColorsWrapper = styled.div`
display: flex;
gap: 12px;
`;

export const ColorsContainer = styled.div`
display: flex;
gap: 12px;
max-width: calc(100% - 80px);
flex-wrap: wrap;
`;

export const AddColor = styled.div`
display: flex;
width: 68px;
height: 68px;
padding: 22px;
justify-content: center;
align-items: center;
flex-shrink: 0;
border-radius: 4px;
border: 0.765px solid #9C9C9C;
background: #FFF;
position: relative;
`;

export const Add = styled.img`

`

export const Color = styled.div`
width: 68px;
height: 68px;
flex-shrink: 0;
border-radius: 4px;
border: 0.765px solid #E8E8E8;
background: ${(props) => props.color ?? "#fff"};
`;

export const UploadImage = styled.div`
display: inline-flex;
padding: 8px 12px;
align-items: center;
gap: 8px;
border-radius: 3px;
border: 1px solid #9C9C9C;
background: #FFF;
color: #757575;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
position: relative;
`;

export const Upload = styled.img`

`;

export const BrandImage = styled.div`
margin-top: 24px;
width: 85.948275862%;
`;

export const Gallery = styled.div`
width: 100%;
height: 22.143579373%;
flex-shrink: 0;
border-radius: 8px;
background: #FFF;
padding: 16px;
color: #1C1C1C;
font-family: Inter;
font-size: 14px;
font-style: italic;
font-weight: 300;
line-height: normal;
display: flex;
flex-direction: column;
gap: 6px;
position: relative;
`;

export const List = styled.div`
display: flex;
gap: 10px;
width: 100%;
overflow: hidden;
height: 69.406392694%;
align-items: center;
padding: 10px 0;
`;

export const ImageContainer = styled.div`
position: relative;
border: ${(props) => props.isSelected ? "4px solid #FF711E" : "none"};
height: 100%;
display: flex;
align-items: center;
`;

export const Image = styled.img`
height: 100%;
max-width: 271px;
cursor: pointer;
`;

export const Selected = styled.img`
position: absolute;
top: -12px;
right: -6px;
`;

export const Navigator = styled.div`
display: flex;
width: 32px;
height: 32px;
padding: 8.664px 7.334px 7.336px 8.666px;
justify-content: center;
align-items: center;
flex-shrink: 0;
border-radius: 53.333px;
background: #FFF;
box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.25);
position: absolute;
top: 119px;
left: ${(props) => props.left ? "-16px" : "unset"};
right: ${(props) => props.right ? "-16px" : "unset"};
cursor: pointer;
`;

export const NavButton = styled.img`

`;

export const NextContainer = styled.div`
width: 100%;
`;

export const NextButton = styled.div`
  display: inline-flex;
  width: 87px;
  height: 52px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: #FF711E;
  color: #FFF;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  cursor: pointer;
  float: right;
  margin-top: 24px;
  opacity: ${props => props.isDisabled ? "0.5": "1"};
  pointer-events: ${props => props.isDisabled ? "none": "all"};
  ${(props) => props.forTextAd ? `
  position: absolute;
  right: calc(100% - 72.4%);`: "" }

`;

export const ColorpickerContainer = styled.div`
position: absolute;
top: 42px;
left: 58px;
display: none;
width: 195px;
height: 188px;
flex-shrink: 0;
background: #D9D9D9;
z-index: 4;

.color-picker-container {
  position: relative;
}
.color-picker-palette {
  position: absolute;
  z-index: 100;
}

.color-picker-cover {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
}

.color-picker-color-background {
  position: fixed;
  height: 35px;
  width: 35px;
  background-color: #999999;
  float: left;
}

`;

export const LableNote = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #9C9C9C;
  margin-bottom: 16px;
`;