import styled from "styled-components";

export const AdIntegrationWrap = styled.div`
  margin: 64px 0 0 100px;
  max-width: 1130px;
  @media screen and (max-width: 1600px) {
    margin: 64px auto auto;
  }
  @media screen and (max-width: 1450px) {
    margin: 64px 35px 30px 30px;
  }
`;
export const IntegrationTitle = styled.div`
  color: #000;
  font-size: 24px;
  font-weight: 600;
  line-height: 38px; /* 158.333% */
`;
export const IntegrationDescrip = styled.div`
  color: rgba(28, 28, 28, 0.5);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  padding: 8px 0 32px 0;
`;
export const AdContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 32px;
`;
export const ConnectBtn = styled.div`
  border-radius: var(--8, 8px);
  background: #ff711e;
  display: flex;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  img {
    height: 16px;
  }
`;
export const AdWrap = styled.div`
  display: flex;
  border-radius: var(--8, 8px);
  background: #fff;
  max-width: 1130px;
  height: 106px;
  align-items: center;
  justify-content: space-between;
  padding: 15px 48px 15px 24px;
  margin-bottom: 24px;
`;
export const AdImg = styled.div`
  border-radius: 4px;
  background: #f6f6f6;
  padding: 15px;
  img {
    height: 46px;
  }
`;
export const AdAccount = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  p {
    color: rgba(28, 28, 28, 0.5);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }
`;
export const AccPlatform = styled.div`
  color: #3f3f3f;
  font-size: 15px;
  font-weight: 600;
  line-height: normal;
`;
